// @flow
import * as React from 'react';

import t from './locale';
import cs from './styles.pcss';

import Logo from './logo.svg';
import Icon from '../../basic-components/icon/index';

type Props = {
  loginUrl: string,
  clarification: boolean,
};

export default function AuthForm({
  loginUrl,
  clarification,
}: Props): React.Node {
  return (
    <div className={cs.dialog}>
      <header className={cs.header}>
        <Icon name="user" className={cs.image} />
        <h1 className={cs.title}>{t('enter')}</h1>
      </header>
      <div className={cs.form}>
        <a href={loginUrl} className={cs.button}>
          {t('button')}
          <Logo className={cs.logo} />
        </a>
      </div>
      {clarification && (
        <div className={cs.clarification}>{t('clarification')}</div>
      )}
    </div>
  );
}
