// @flow
import * as React from 'react';

import Layout from '../../components/layout';
import AuthForm from '../../components/auth-form';
import cs from './styles.pcss';

type Props = {
  loginUrl: string,
  clarification: boolean,
};

export default function SignInPage({
  loginUrl,
  clarification,
}: Props): React.Node {
  return (
    <Layout page="login" unauthorized loading={false}>
      <div className={cs.wrapper}>
        <AuthForm loginUrl={loginUrl} clarification={clarification} />
      </div>
    </Layout>
  );
}
