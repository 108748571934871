import qs from 'qs';
import settings, { skipOnboardingQuery } from '../../settings';

/**
 * login url redirects to parcels with "skip onboarding" flag => show clarification
 */
const showClarification = () => {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const redirect = params.redirect_to;
  if (!redirect || redirect.indexOf(settings.path.parcels) === -1) return false;
  return redirect.indexOf(skipOnboardingQuery) !== -1;
};

export default function mapDataToState({ loginUrl }) {
  return { signIn: { loginUrl, clarification: showClarification() } };
}
