import { connect } from 'react-redux';

import SignInPage from './component';

function mapStateToProps({ signIn, footer }) {
  return {
    loginUrl: signIn.loginUrl,
    footer,
    clarification: signIn.clarification,
  };
}

export default connect(mapStateToProps)(SignInPage);
